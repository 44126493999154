import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// ----------------------------------------------------------------------
Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">



<path d="M1900 2129 c0 -25 -39 -69 -61 -69 -26 0 -24 -18 3 -22 12 -2 32 -17
43 -34 l21 -31 17 28 c9 16 29 34 44 41 25 10 26 12 8 16 -29 6 -46 23 -61 57
-7 16 -13 23 -14 14z"/>
<path d="M1381 2110 c-30 -11 -73 -35 -98 -54 -42 -33 -103 -101 -103 -116 0
-4 -29 -10 -63 -13 -110 -10 -147 -40 -105 -85 24 -25 69 -52 88 -52 22 0 8
16 -35 39 -71 38 -54 66 47 78 48 6 51 5 44 -13 -55 -144 -15 -319 96 -419 90
-81 182 -112 296 -102 186 18 329 164 340 350 2 42 7 77 9 77 14 0 113 -53
124 -66 18 -22 -7 -40 -66 -49 -25 -3 -45 -11 -45 -16 0 -13 73 -3 110 15 29
13 37 40 18 63 -7 9 -46 31 -85 49 -56 26 -73 38 -73 54 0 69 -112 203 -208
248 -77 36 -211 42 -291 12z m275 -36 c13 -15 21 -30 19 -32 -1 -2 -19 1 -39
7 -20 5 -75 9 -121 8 -47 -1 -85 2 -85 7 0 5 51 6 113 4 61 -3 105 -3 97 -1
-8 3 -22 7 -30 10 -8 3 -30 5 -49 4 -19 -2 -37 3 -42 11 -7 10 -9 10 -9 1 0
-8 -14 -13 -35 -13 -26 0 -35 4 -35 16 0 14 12 16 97 12 92 -5 98 -6 119 -34z
m-226 17 c0 -12 -25 -21 -56 -21 -14 0 -27 -7 -30 -15 -4 -9 -18 -22 -33 -31
-24 -14 -24 -14 -2 -8 17 4 22 2 19 -7 -3 -8 -21 -18 -41 -24 -20 -6 -45 -18
-54 -28 -17 -17 -17 -18 2 -11 11 4 22 11 25 15 3 4 18 11 33 14 24 6 27 5 27
-19 0 -25 -2 -26 -55 -26 -65 0 -69 10 -24 60 17 19 26 27 19 18 -10 -14 -10
-18 0 -18 6 0 14 9 17 20 3 11 10 20 15 20 5 0 6 4 3 10 -7 11 54 52 66 44 4
-2 10 0 14 6 8 12 55 13 55 1z m270 -21 c20 -11 65 -78 58 -86 -3 -2 -17 0
-32 5 -32 13 -35 34 -3 27 18 -4 18 -3 -3 6 -14 5 -31 21 -37 34 -12 27 -10
29 17 14z m-192 -32 c-5 -15 -88 -24 -88 -9 0 10 26 17 68 20 15 0 22 -4 20
-11z m70 6 c36 -10 25 -24 -18 -24 -29 0 -40 4 -40 15 0 16 20 19 58 9z m-170
-16 c-6 -18 -68 -29 -68 -12 0 11 19 19 58 23 8 0 12 -5 10 -11z m256 2 c26
-9 38 -33 14 -26 -7 3 -25 7 -40 10 -16 4 -28 11 -28 16 0 13 22 13 54 0z
m-154 -50 c0 -24 -4 -30 -22 -30 -13 0 -33 -3 -45 -6 -21 -6 -23 -3 -23 30 l0
36 45 0 c43 0 45 -1 45 -30z m94 14 c3 -9 6 -25 6 -36 0 -16 -5 -19 -22 -14
-13 3 -33 6 -45 6 -19 0 -23 6 -23 30 0 28 2 30 39 30 26 0 41 -5 45 -16z
m190 -9 c21 -32 20 -38 -3 -26 -19 10 -36 51 -21 51 4 0 15 -11 24 -25z m-384
-14 c0 -30 -14 -41 -56 -41 -20 0 -24 5 -24 29 0 30 11 37 58 40 18 1 22 -4
22 -28z m280 10 c5 -11 10 -29 10 -41 0 -17 -4 -20 -20 -16 -11 3 -29 9 -40
12 -14 4 -20 14 -20 35 0 26 4 29 30 29 19 0 33 -7 40 -19z m61 -11 c12 -7 19
-21 19 -40 0 -34 0 -34 -35 -18 -18 8 -25 19 -25 40 0 30 10 34 41 18z m61
-37 c10 -9 18 -28 19 -42 l1 -26 7 24 6 24 13 -27 c23 -51 15 -57 -33 -24 -35
25 -45 38 -45 60 0 32 6 35 32 11z m-168 -12 c26 -5 51 -12 55 -17 3 -5 17
-10 31 -11 25 -1 105 -42 97 -49 -1 -2 -32 5 -67 16 -36 10 -135 30 -220 44
l-155 24 105 0 c58 1 127 -3 154 -7z m-332 -15 c4 3 8 -13 8 -36 0 -35 -4 -42
-26 -51 -31 -11 -30 -13 -38 28 -6 24 -3 34 11 42 17 10 16 11 -3 8 -12 -2
-33 -11 -48 -20 -19 -13 -26 -14 -27 -5 -1 7 -4 2 -8 -12 -4 -14 -3 -40 2 -59
6 -22 5 -37 -2 -44 -16 -16 -13 -27 5 -20 12 4 15 0 12 -17 -5 -38 -23 -34
-35 7 -9 32 -11 34 -12 12 -1 -31 38 -114 74 -158 15 -17 25 -35 21 -38 -8 -9
-56 37 -56 54 0 7 -4 13 -10 13 -5 0 -10 9 -10 19 0 11 -4 22 -9 26 -19 11
-24 95 -8 135 10 27 12 40 4 43 -14 4 10 64 30 76 8 4 38 4 65 0 28 -5 55 -6
60 -3z m149 -11 c105 -14 249 -39 292 -51 9 -3 17 -12 17 -21 0 -20 -49 -9
-67 14 -7 9 -13 12 -13 6 0 -7 -14 -8 -42 -2 -24 4 -59 8 -78 7 l-35 -1 33 -4
c24 -3 32 -9 32 -24 0 -16 -7 -19 -40 -19 l-40 0 0 41 c0 37 -1 39 -16 25 -8
-9 -31 -16 -50 -16 -28 0 -34 4 -35 23 l-2 22 -4 -23 c-5 -24 -8 -26 -55 -36
-27 -6 -28 -5 -28 34 0 22 4 40 8 40 5 0 60 -7 123 -15z m-217 -57 c5 -26 2
-36 -16 -51 -23 -18 -23 -18 -1 -12 32 9 40 -15 12 -36 -13 -10 -19 -19 -12
-19 6 0 15 5 18 10 4 6 11 8 16 5 15 -10 10 -29 -11 -43 -18 -11 -21 -9 -30
14 -6 15 -12 34 -14 43 -1 9 -6 33 -11 54 -6 31 -4 41 11 52 27 20 31 19 38
-17z m266 -19 c0 -16 -7 -19 -45 -19 -38 0 -45 3 -45 19 0 16 8 21 38 23 46 4
52 2 52 -23z m176 0 c3 -5 1 -15 -3 -22 -12 -20 -73 -2 -73 21 0 15 7 17 35
14 19 -2 38 -8 41 -13z m-276 -3 c0 -17 -5 -21 -42 -30 -23 -5 -28 -3 -28 12
0 25 9 32 42 32 18 0 28 -5 28 -14z m451 -2 c4 -2 9 -25 12 -51 7 -51 -4 -125
-16 -107 -3 6 -1 26 6 43 10 28 10 34 -6 42 -9 6 -18 22 -18 37 -1 16 -3 20
-6 10 -4 -14 -8 -15 -29 -6 -13 6 -24 20 -24 31 0 17 5 18 37 12 21 -4 41 -9
44 -11z m-531 -14 c0 -21 -43 -45 -61 -34 -17 10 -9 30 19 41 39 16 42 15 42
-7z m430 -22 c0 -21 1 -21 -39 -7 -34 12 -33 11 -25 32 8 21 64 -1 64 -25z
m-342 -23 c-1 -1 -18 -6 -35 -10 -26 -5 -33 -3 -33 9 0 21 64 38 68 18 2 -7 2
-15 0 -17z m92 21 c0 -7 -10 -16 -22 -20 -42 -11 -58 -7 -58 14 0 17 7 20 40
20 27 0 40 -4 40 -14z m80 -7 c0 -19 -4 -21 -30 -15 -17 3 -30 1 -30 -4 0 -6
14 -10 30 -10 32 0 33 -2 24 -49 -6 -26 -11 -31 -35 -31 -29 0 -29 0 -29 65
l0 65 35 0 c29 0 35 -4 35 -21z m228 -23 c4 -19 -19 -21 -36 -4 -7 7 -12 21
-12 31 0 18 2 18 23 5 12 -9 23 -23 25 -32z m-148 24 c24 -15 -2 -31 -38 -24
-40 8 -43 34 -4 34 15 0 34 -5 42 -10z m-330 -19 c0 -12 -11 -23 -30 -30 -26
-9 -30 -8 -30 8 0 10 10 23 23 29 32 15 37 14 37 -7z m410 -6 c0 -2 -3 -10 -6
-19 -5 -13 -27 -10 -62 10 -1 0 0 8 4 17 4 12 11 13 35 5 16 -5 29 -11 29 -13z
m-240 -46 c0 -39 -1 -40 -32 -37 -30 3 -33 6 -36 40 -3 33 -1 37 25 40 40 5
43 2 43 -43z m-80 12 c0 -16 3 -36 6 -45 5 -12 -1 -16 -24 -16 -25 0 -32 6
-41 31 -15 42 -14 47 7 52 43 11 52 7 52 -22z m227 19 c9 0 9 -9 -1 -37 -10
-30 -19 -39 -41 -41 -26 -3 -28 -2 -21 28 3 18 6 39 6 47 0 11 7 14 23 9 12
-3 27 -6 34 -6z m143 -31 c0 -17 -3 -18 -22 -8 -30 16 -29 14 -22 32 8 23 44
3 44 -24z m54 1 c-14 -14 -39 14 -29 31 5 8 12 6 23 -5 11 -11 13 -19 6 -26z
m-496 -17 c13 -42 9 -53 -18 -53 -13 0 -25 12 -35 36 -18 45 -19 42 3 47 34 9
39 6 50 -30z m371 21 c2 -2 -4 -22 -14 -43 -16 -36 -19 -39 -40 -29 -13 6 -22
16 -20 22 23 65 25 67 48 60 12 -3 24 -8 26 -10z m-515 -37 c-7 -19 -20 -22
-29 -7 -9 15 16 43 27 32 5 -5 6 -16 2 -25z m85 -58 c-10 -6 -21 -1 -36 19
-28 33 -28 41 -5 58 15 11 19 8 36 -28 16 -34 17 -41 5 -49z m545 49 c15 -29
15 -33 -1 -50 -9 -10 -17 -27 -18 -38 -2 -31 -107 -136 -124 -126 -13 8 -42
-4 -35 -15 3 -5 -6 -9 -20 -9 -16 0 -26 6 -26 15 0 9 6 12 18 9 9 -3 2 3 -16
13 l-33 19 31 29 c16 16 33 25 37 21 5 -4 -5 -19 -20 -32 -34 -29 -26 -32 14
-3 24 17 29 18 29 5 0 -22 8 -20 47 14 38 34 56 39 46 13 -6 -16 -5 -16 11 -4
12 10 14 16 5 21 -8 6 -4 18 13 44 14 20 28 33 31 29 4 -3 7 2 7 13 0 11 -9
25 -20 32 -11 7 -17 18 -14 27 8 20 19 12 38 -27z m-50 3 l28 -30 -21 -30
c-13 -17 -26 -31 -31 -31 -20 0 -19 21 1 50 13 16 20 32 17 36 -3 3 -14 -8
-24 -25 -20 -34 -22 -35 -42 -22 -11 6 -10 15 4 44 21 45 32 46 68 8z m-544
-37 c17 -26 20 -38 11 -47 -6 -6 -12 -9 -14 -6 -45 59 -47 62 -36 75 15 19 14
19 39 -22z m260 -14 c0 -33 -3 -40 -19 -40 -23 0 -26 4 -36 48 -6 31 -5 32 24
32 29 0 31 -2 31 -40z m60 23 c0 -38 -13 -63 -31 -63 -15 0 -19 7 -19 40 0 36
3 40 25 40 17 0 25 -5 25 -17z m-122 -33 c9 -36 8 -40 -9 -40 -10 0 -25 13
-34 30 -21 40 -19 50 9 50 19 0 25 -7 34 -40z m182 31 c0 -30 -24 -71 -42 -71
-17 0 -19 4 -13 33 4 17 9 35 12 40 6 10 43 9 43 -2z m-238 -40 c13 -33 14
-41 2 -45 -13 -5 -54 42 -54 62 0 9 14 19 31 21 3 1 13 -17 21 -38z m298 22
c0 -2 -10 -19 -23 -38 -31 -46 -54 -34 -32 17 11 29 19 35 35 31 11 -3 20 -7
20 -10z m40 -23 c0 -15 -42 -60 -56 -60 -19 0 -18 4 8 44 19 29 48 39 48 16z
m-385 -16 c9 -14 32 -43 52 -65 19 -21 32 -39 28 -39 -15 0 -65 42 -60 51 4 5
1 9 -6 9 -7 0 -26 12 -41 27 -15 15 -28 22 -28 17 0 -6 11 -21 25 -34 36 -34
20 -45 -21 -14 l-35 27 22 23 c29 31 43 30 64 -2z m386 -46 c-42 -38 -52 -23
-14 20 19 22 30 29 38 21 7 -7 -1 -20 -24 -41z m-221 -3 c0 -44 -14 -45 -29
-4 -14 41 -14 39 9 39 16 0 20 -7 20 -35z m40 17 c0 -10 -7 -27 -15 -38 -13
-18 -14 -17 -15 19 0 26 4 37 15 37 8 0 15 -8 15 -18z m-81 -18 c6 -15 9 -29
6 -33 -7 -7 -45 36 -45 50 0 20 28 7 39 -17z m160 11 c3 -3 -6 -17 -20 -30
-16 -17 -31 -23 -45 -19 -19 5 -20 9 -10 36 10 26 15 29 40 24 16 -4 32 -9 35
-11z m-339 -18 c15 -13 26 -35 28 -52 1 -23 -1 -26 -8 -15 -5 8 -10 11 -10 7
0 -8 -50 42 -50 50 0 2 6 1 13 -3 10 -6 10 -4 0 7 -31 33 -9 38 27 6z m145 -3
c18 -28 14 -44 -7 -23 -35 37 -41 49 -25 49 8 0 22 -12 32 -26z m-55 -26 c34
-25 40 -63 11 -71 -33 -8 -46 10 -15 21 22 8 24 11 9 11 -14 1 -16 4 -7 9 9 6
6 12 -13 20 -14 6 -25 16 -25 22 0 15 7 13 40 -12z m209 -32 c30 -30 -1 -51
-104 -71 -24 -4 -26 -3 -10 4 20 10 20 10 -1 10 -11 1 -29 7 -40 15 -15 12
-17 18 -8 33 6 10 22 21 35 25 34 10 111 0 128 -16z"/>
<path d="M1980 1956 c-10 -13 -10 -19 0 -32 12 -14 15 -14 29 0 14 15 14 17 0
32 -14 14 -17 14 -29 0z"/>
<path d="M1000 1649 c0 -3 9 -24 21 -48 11 -23 19 -50 17 -59 -2 -9 -24 -30
-50 -47 -61 -39 -61 -50 0 -19 67 34 78 31 115 -27 17 -28 33 -47 35 -41 1 5
-6 25 -17 43 -27 43 -26 67 0 95 l22 23 -35 -5 c-32 -6 -38 -3 -64 32 -35 49
-44 59 -44 53z"/>
<path d="M2098 1466 c-50 -18 -90 -45 -83 -56 3 -5 0 -12 -6 -16 -8 -4 -9 -3
-5 4 4 7 2 12 -3 12 -14 0 -14 -17 0 -25 5 -4 18 -1 27 7 47 39 139 51 180 22
13 -9 22 -26 22 -42 1 -22 2 -24 10 -10 13 22 -3 63 -29 77 -30 17 -91 13
-138 -9 -24 -10 -43 -17 -43 -14 0 13 97 46 136 46 48 1 71 -13 90 -57 l13
-30 1 32 c0 64 -81 91 -172 59z"/>
<path d="M532 1293 c3 -41 26 -57 35 -24 7 27 19 27 26 -1 5 -18 8 -19 21 -8
22 17 21 70 0 70 -10 0 -14 -6 -10 -15 3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0
8 -4 15 -10 15 -5 0 -10 -7 -10 -15 0 -8 -5 -15 -11 -15 -6 0 -9 7 -5 15 3 9
0 15 -10 15 -11 0 -14 -9 -12 -37z"/>
<path d="M649 1293 c8 -45 26 -53 39 -16 l10 28 7 -27 c7 -29 23 -37 29 -15
15 47 16 67 2 67 -8 0 -17 -10 -19 -22 l-4 -23 -2 23 c0 12 -5 22 -9 22 -5 0
-14 -10 -20 -22 l-11 -23 0 23 c-1 12 -7 22 -14 22 -10 0 -12 -10 -8 -37z"/>
<path d="M765 1298 c13 -57 28 -65 40 -20 l7 27 10 -28 c13 -37 31 -29 39 16
4 27 2 37 -8 37 -7 0 -13 -10 -14 -22 l0 -23 -9 23 c-5 12 -14 22 -19 22 -5 0
-12 -10 -14 -22 l-4 -23 -2 23 c0 13 -7 22 -17 22 -12 0 -14 -7 -9 -32z"/>
<path d="M910 1291 c0 -37 2 -41 26 -41 44 0 63 16 55 47 -6 23 -14 29 -44 31
l-37 3 0 -40z"/>
<path d="M1010 1296 c0 -35 13 -46 51 -46 10 0 19 5 19 10 0 6 -9 10 -20 10
-16 0 -20 7 -20 30 0 20 -5 30 -15 30 -10 0 -15 -10 -15 -34z"/>
<path d="M1092 1298 c2 -25 9 -34 28 -38 17 -4 21 -8 12 -14 -6 -4 -12 -21
-12 -37 0 -26 3 -30 23 -27 26 3 37 46 15 59 -10 7 -10 9 0 9 6 0 12 5 12 10
0 6 -11 10 -25 10 -14 0 -25 5 -25 11 0 6 7 8 16 5 9 -3 21 -3 27 1 7 5 0 10
-18 14 l-30 7 28 1 c43 2 31 21 -14 21 -39 0 -40 -1 -37 -32z"/>
<path d="M1190 1315 c-15 -17 -3 -38 17 -31 8 3 20 3 26 -1 7 -5 -2 -10 -23
-14 -27 -4 -31 -7 -17 -13 27 -13 67 2 67 24 0 15 -7 20 -27 21 -27 2 -27 2
-3 6 42 8 47 23 8 23 -19 0 -41 -7 -48 -15z"/>
<path d="M1287 1323 c-4 -3 -7 -15 -7 -25 0 -15 5 -17 25 -12 15 4 25 2 25 -5
0 -6 -11 -11 -25 -11 -14 0 -25 -4 -25 -10 0 -13 36 -13 61 0 30 16 23 38 -13
43 l-33 4 33 1 c17 1 32 7 32 12 0 11 -63 14 -73 3z"/>
<path d="M1370 1302 c0 -31 16 -52 40 -52 12 0 15 -8 12 -35 -3 -23 0 -35 8
-35 8 0 11 11 8 30 -3 17 -1 33 4 36 17 11 7 24 -17 24 -14 0 -25 5 -25 11 0
6 7 8 16 5 9 -3 21 -3 27 1 7 5 0 10 -18 14 l-30 7 28 1 c43 2 32 21 -13 21
-37 0 -40 -2 -40 -28z"/>
<path d="M1470 1291 c0 -38 2 -41 29 -41 34 0 54 20 49 52 -2 19 -10 24 -40
26 l-38 3 0 -40z m50 -1 c0 -11 -7 -20 -15 -20 -8 0 -15 9 -15 20 0 11 7 20
15 20 8 0 15 -9 15 -20z"/>
<path d="M1562 1293 c3 -33 6 -38 31 -41 37 -4 59 15 55 47 -3 23 -8 26 -46
29 l-43 3 3 -38z m58 -3 c0 -11 -7 -20 -15 -20 -8 0 -15 9 -15 20 0 11 7 20
15 20 8 0 15 -9 15 -20z"/>
<path d="M1670 1305 c-11 -28 -13 -55 -5 -55 3 0 15 6 26 13 17 10 23 10 26 0
3 -7 11 -13 19 -13 11 0 14 10 12 38 -3 34 -6 37 -36 40 -27 3 -34 -1 -42 -23z"/>
<path d="M1760 1320 c0 -5 7 -10 15 -10 10 0 15 -10 15 -31 0 -36 23 -35 28 1
2 13 11 30 20 37 14 10 10 12 -30 13 -27 0 -48 -4 -48 -10z"/>
<path d="M1867 1323 c-11 -10 -8 -73 3 -73 6 0 10 5 10 10 0 6 9 10 20 10 11
0 20 -5 20 -11 0 -6 5 -9 12 -7 16 6 21 48 7 64 -12 14 -61 19 -72 7z"/>
<path d="M1997 1323 c-11 -11 -8 -48 5 -61 14 -14 68 -16 68 -2 0 6 -11 10
-25 10 -18 0 -25 5 -25 20 0 15 7 20 25 20 14 0 25 5 25 10 0 11 -63 14 -73 3z"/>
<path d="M2084 1305 c-9 -35 12 -57 50 -53 28 3 31 6 31 38 0 33 -2 35 -37 38
-33 3 -38 0 -44 -23z m64 -12 c-5 -26 -38 -29 -38 -4 0 15 6 21 21 21 14 0 19
-5 17 -17z"/>
<path d="M2184 1293 c2 -46 21 -54 30 -12 7 34 1 49 -18 49 -8 0 -12 -13 -12
-37z"/>
<path d="M2243 1313 c-4 -10 -11 -21 -16 -24 -6 -4 -7 -15 -2 -25 7 -18 8 -18
15 1 7 17 9 17 9 3 1 -10 8 -18 16 -18 11 0 15 11 15 40 0 29 -4 40 -15 40 -9
0 -19 -8 -22 -17z"/>
<path d="M2328 1290 c4 -40 22 -57 22 -20 0 26 12 26 28 -1 19 -29 34 -19 37
24 1 25 -2 37 -12 37 -7 0 -13 -10 -13 -22 l-1 -23 -18 23 c-30 35 -47 29 -43
-18z"/>
<path d="M2437 1323 c-4 -3 -7 -19 -7 -34 0 -22 6 -30 24 -35 14 -3 34 -3 45
1 27 8 28 45 1 45 -13 0 -18 -5 -14 -15 4 -9 0 -15 -10 -15 -9 0 -16 8 -16 20
0 16 7 20 30 20 17 0 30 5 30 10 0 11 -73 14 -83 3z"/>
<path d="M1260 1227 c0 -22 11 -47 20 -47 13 0 5 53 -9 58 -6 2 -11 -3 -11
-11z"/>
<path d="M1036 1214 c-22 -58 13 -98 105 -119 l64 -15 -58 5 c-100 9 -145 48
-132 113 5 27 5 27 -10 -3 -24 -44 -7 -81 47 -105 66 -30 215 -21 215 13 0 9
-19 11 -71 9 -106 -6 -160 32 -143 98 6 24 -8 27 -17 4z"/>
<path d="M1196 1214 c-8 -20 1 -34 20 -34 8 0 14 10 14 25 0 28 -24 34 -34 9z"/>
<path d="M1317 1211 c-6 -24 0 -31 26 -31 13 0 17 5 13 16 -3 9 -6 20 -6 25 0
17 -28 9 -33 -10z"/>
<path d="M1460 1205 c0 -15 6 -25 14 -25 19 0 28 14 20 34 -10 25 -34 19 -34
-9z"/>
<path d="M1560 1193 l0 -37 20 22 c11 12 20 28 20 37 0 8 -9 15 -20 15 -16 0
-20 -7 -20 -37z"/>
<path d="M1623 1214 c-7 -18 11 -39 26 -30 17 11 13 46 -4 46 -9 0 -18 -7 -22
-16z"/>
<path d="M1680 1222 c0 -5 7 -17 15 -28 11 -14 14 -15 15 -4 0 13 1 13 10 0 7
-12 11 -10 18 10 11 29 5 41 -8 20 -9 -13 -10 -13 -10 0 0 13 -1 13 -10 0 -6
-10 -10 -11 -10 -2 0 6 -4 12 -10 12 -5 0 -10 -4 -10 -8z"/>
<path d="M1770 1205 c0 -28 24 -34 34 -9 8 20 -1 34 -20 34 -8 0 -14 -10 -14
-25z"/>
<path d="M1830 1203 l0 -27 20 22 c11 12 20 24 20 27 0 3 -9 5 -20 5 -15 0
-20 -7 -20 -27z"/>
<path d="M1302 1131 c-19 -12 -11 -40 9 -32 10 4 14 12 10 23 -4 11 -11 14
-19 9z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
